<template>
  <div class="app-container">
    <el-dialog title="" :visible.sync="dialogNPAView" width="70%">
      <div v-if="document">
        <NPA :document="document" />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="2500"
            :filename="`NPA - ${document.employee_details.name}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="legal"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <NPA :document="document" />
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNPAView = false">Cancel</el-button>
        <el-button type="primary" @click="generateReport">Download</el-button>
        <el-button v-if="showApproval === '1'" type="danger" @click="disapprove">Disapprove</el-button>
        <el-button v-if="showApproval === '1'" type="success" @click="approve">Approve</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Approvers" :visible.sync="dialogApproverVisible">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.file_status)">{{ props.row.file_status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span v-if="scope.row.a_date_action" style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-drawer title="Comments" :visible.sync="drawerComments" direction="rtl">
      <div class="drawer">
        <el-timeline>
          <el-timeline-item v-for="comment in comments" :key="comment.created_at" :timestamp="formatDate(comment.created_at)" placement="top">
            <el-card>
              <h4>{{ comment.created_by_name }}</h4>
              <p>{{ comment.comment }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">DOCUMENT APPROVAL DASHBOARD</h3>
      </div>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column label="EMPLOYEE" prop="em_id_name"></el-table-column>
        <el-table-column label="DESCRIPTION" prop="npa_bg4_id_name"></el-table-column>
        <el-table-column label="DATE HIRED" prop="datehired">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.datehired) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="mss_a_id_name">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.mss_a_id_name)">{{ props.row.mss_a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="200">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="View Document" placement="top-start">
              <el-button size="mini" type="primary" @click="view(scope.row)"><i class="el-icon-search"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Comments" placement="top-start">
              <el-button size="mini" type="warning" @click="getComments(scope.row.hed_id)"><i class="el-icon-s-comment"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Status" placemnt="top-start">
              <el-button size="mini" type="info" @click="getDocumentStatus(scope.row.hed_id)"><i class="el-icon-edit-outline"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
import NPA from '@/components/Documents/NPA.vue'

export default {
  components: {
    NPA
  },
  data () {
    return {
      dialogApproverVisible: false,
      dialogView: false,
      dialogNPAView: false,
      drawerComments: false,
      comments: [],
      approvers: [],
      data: [],
      showApproval: false,
      npa: null,
      document: null,
      forApproval: {}
    }
  },
  mounted () {
    this.getFiles()
  },
  methods: {
    getFiles () {
      this.$http
        .get('hr.document-approval-list')
        .then(res => {
          this.data = res.data.body
        })
    },
    approve () {
      this.$confirm('Are you sure you want to approve this document?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          const form = {
            a_id: 2,
            id: this.forApproval.id,
            hed_id: this.forApproval.hed_id,
            boss_em_id: this.forApproval.boss_em_id,
            hierarchy_no: this.forApproval.hierarchy_no
          }
          this.$http
            .post('hr.document-update', form)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Document approved successfully'
              })
              if (this.document.bar_id === 1) {
                this.dialogNPAView = false
              } else if (this.document.bar_id === 2) {
                this.dialogMPPView = true
              }
              this.getFiles()
            })
        })
    },
    disapprove () {
      this.$confirm('Are you sure you want to disapprove this document?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          const form = {
            a_id: 3,
            id: this.forApproval.id,
            hed_id: this.forApproval.hed_id,
            boss_em_id: this.forApproval.boss_em_id,
            hierarchy_no: this.forApproval.hierarchy_no
          }
          this.$http
            .post('hr.document-update', form)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Document disapproved successfully'
              })
              if (this.document.bar_id === 1) {
                this.dialogNPAView = false
              } else if (this.document.bar_id === 2) {
                this.dialogMPPView = true
              }
              this.getFiles()
            })
        })
    },
    getComments (id) {
      this.$http
        .get(`hr.document-comment-list/${id}`)
        .then(res => {
          this.comments = res.data.body
          this.drawerComments = true
        })
    },
    getDocumentStatus (id) {
      this.$http
        .get(`hr.document-approver-list/${id}`)
        .then(res => {
          this.approvers = res.data.body
          this.dialogApproverVisible = true
        })
    },
    view (data) {
      this.forApproval = data
      this.showApproval = data.is_action_taken
      this.$http
        .get(`hr.document-form-data/${data.hed_id}/${data.bar_id}/${data.em_id}`)
        .then(res => {
          this.document = res.data.body
          if (this.document.bar_id === 1) {
            this.dialogNPAView = true
          } else if (this.document.bar_id === 2) {
            this.dialogMPPView = true
          }
        })
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    },
    getStatusType (type) {
      if (type === 'Waiting' || type === 'Hold' || type === 'For Revision') {
        return 'primary'
      } else if (type === 'Submitted' || type === 'For Signature' || type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    }
  }
}
</script>
